import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import not_found from '../assets/illustrations/stylish/settings-1768784-0.svg'
import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from '../images/favicon/tf_round_blue.png'

const NotFoundPage = () => (
  // <Layout>
  <>
    <Seo title="404: Not found" />
    <div className="flex flex-col flex-wrap content-center justify-center h-screen w-screen text-center">
      {/* <StaticImage
        className="max-h-20 w-auto object-contain"
        src={logo}
        placeholder="tracedSVG"
        alt=""
      /> */}
      <p className="font-semibold text-darkblue-tf mt-6 text-3xl">404</p>
      <h1 className="font-black text-gray-700 mt-1 text-5xl ">Page not found.</h1>
      <p className="font-normal text-gray-400 mt-2 text-lg">Sorry, we couldn't find the page you are looking for.</p>
      <div>
        <Link to="/#" className="font-normal text-darkblue-tf mt-4 text-lg  flex content-center flex-wrap justify-center gap-x-2">Go back home
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-darkblue-tf " fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </Link>
      </div>
    </div>
  </>
  // </Layout>
)

export default NotFoundPage
